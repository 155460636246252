<template>
  <nav class="h-auto bg-white w-full absolute self-center z-30">
    <div class="mx-5 2xl:mx-72 items-center">
        <div class="grid grid-cols-12 items-center py-2">
            <div class="col-span-12 lg:col-span-2">
              <router-link to="/home">
                <img class="h-18 pt-2 pb-3 w-auto" alt="logo boscardin" src="@/assets/logo.png"  />
              </router-link>
            </div>
            <div :class="{'block': open, 'hidden': !open}" class="col-span-12 md:col-span-7 lg:col-span-6 flex-grow lg:block lg:overflow-y-auto">
              <router-link @click.native="open = false" active-class="text-primary font-semibold" class="text-base text-gray-500 mx-2 md:mx-4 lg:mx-8 my-3 md:my-0 block md:inline-block hover:text-primary" to="/home">Início</router-link>
              <router-link @click.native="open = false" active-class="text-primary font-semibold" class="text-base text-gray-500 mx-2 md:mx-4 lg:mx-8 my-3 md:my-0 block md:inline-block hover:text-primary" to="/empresa">Empresa </router-link>
              <router-link @click.native="open = false" active-class="text-primary font-semibold" class="text-base text-gray-500 mx-2 md:mx-4 lg:mx-8 my-3 md:my-0 block md:inline-block hover:text-primary" to="/servicos">Serviços </router-link>
              <router-link @click.native="open = false" active-class="text-primary font-semibold" class="text-base text-gray-500 mx-2 md:mx-4 lg:mx-8 my-3 md:my-0 block md:inline-block hover:text-primary" to="/social">Responsabilidade social  </router-link>            
            </div>
            <div :class="{'block': open, 'hidden': !open}" class="col-span-12 md:col-span-3 lg:col-span-4 flex-grow lg:block lg:overflow-y-auto">
              <router-link @click.native="open = false" active-class="font-semibold" class="text-base text-primary mx-2 lg:mx-4 my-3 md:my-0 block md:inline-block hover:text-primary" to="/onde">
                <span class="flex">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                  </svg>      
                  ONDE ESTAMOS
                </span>
              </router-link>
              <!--<router-link @click.native="open = false" active-class="font-semibold" class="text-base text-primary mx-2 lg:mx-4 my-3 md:my-0 block md:inline-block hover:text-primary" to="/contato">
                <span class="flex">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                  </svg>      
                  CONTATO
                </span>
              </router-link>-->
            </div>
        </div>
        <svg @click="open = !open" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 absolute top-7 right-4 md:hidden" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      open: false,
    }
  }, 
}
</script>


