import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/empresa',
    name: 'Empresa',
    component: () => import('../views/Empresa.vue'),
  },
  {
    path: '/servicos',
    name: 'Servicos',
    component: () => import('../views/Servicos.vue'),
  },
  {
    path: '/social',
    name: 'Social',
    component: () => import('../views/Social.vue'),
  },
  {
    path: '/onde',
    name: 'Onde',
    component: () => import('../views/Onde.vue'),
  },
  {
    path: '/contato',
    name: 'Contato',
    component: () => import('../views/Contato.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
