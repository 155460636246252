
<template>
    <div class="py-8 md:pt-16 mx-5 md:mx-12 2xl:mx-96">
        <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 md:col-span-3">
                <router-link to="/home">
                    <img class="h-18 w-auto" alt="logo boscardin" src="@/assets/logo.png"  />
                </router-link>
            </div>
            <div class="col-span-12 md:col-span-3">
                <label class="text-gray-700 font-Montserrat font-semibold text-sm">ENDEREÇO</label>
                <p class="text-gray-500 font-Montserrat text-sm mt-2">
                    R. Nicolau José Gravina, 2066 - Cascatinha Curitiba - PR <br/>CEP: 82015-080
                </p>
                <br/>
                <label class="text-gray-700 font-Montserrat font-semibold text-sm">CONTATO</label>
                <p class="text-gray-500 font-Montserrat text-sm mt-2"> (41) 3374-3735</p>
                <p class="text-gray-500 font-Montserrat text-sm mt-2"> contato@pedreirasboscardin.com.br</p>
            </div>
            <div class="col-span-12 md:col-span-3">
                <label class="text-gray-700 font-Montserrat font-semibold text-sm">NAVEGAÇÃO</label>
                <router-link class="text-gray-500 font-Montserrat text-sm mt-2 block hover:underline" to="/">Início</router-link>
                <router-link class="text-gray-500 font-Montserrat text-sm mt-2 block hover:underline" to="/empresa">Empresa </router-link>
                <router-link class="text-gray-500 font-Montserrat text-sm mt-2 block hover:underline" to="/servicos">Serviços </router-link>
                <router-link class="text-gray-500 font-Montserrat text-sm mt-2 block hover:underline" to="/social">Responsabilidade social </router-link>
                <router-link class="text-gray-500 font-Montserrat text-sm mt-2 block hover:underline" to="/onde">Onde estamos </router-link>
                <router-link class="text-gray-500 font-Montserrat text-sm mt-2 block hover:underline" to="/contato">Contato </router-link>
            </div>
            <div class="col-span-12 md:col-span-3">
                <label class="text-gray-700 font-Montserrat font-semibold text-sm">REDES SOCIAS</label>
                <a class="text-gray-500 font-Montserrat text-sm mt-2 block hover:underline" target="_blank" rel="noreferrer" href="https://www.facebook.com/pedreiras.boscardin.1">Facebook</a>
                <a class="text-gray-500 font-Montserrat text-sm mt-2 block hover:underline" target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/pedreiras-boscardin/about/">Linkedin</a>
            </div>
            <div class="col-span-12 md:col-span-12">
                <hr class="border border-gray-200 mt-2" />
            </div>
            <div class="col-span-12 md:col-span-12">
                <center>
                    <p class="text-gray-500 font-Montserrat text-sm mt-2"><b>Pedreiras Boscardin.</b> Todos os direitos reservados.</p> 
                    <p class="text-gray-500 font-Montserrat text-sm mt-2 inline-block">
                     <a class="flex" target="_blank" rel="noreferrer" href="https://ashita.com.br/">
                        <span class="pt-1 pr-1 text-xs">Desenvolvido por:</span> <img class="h-5 pt-1" alt="logo ashita" src="@/assets/ashita.png"  />
                     </a>
                    </p>
                </center>
            </div>
        </div>
    </div>
</template>

<script>
export default {}
</script>