<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
    <img @click="openModal()" class="h-16 md:h-20 fixed bottom-3 md:bottom-8 right-2 md:right-10" src="@/assets/WhatsApp.png" alt="whastapp boscardin"/>
    <modal name="modal_zap" :shiftX="0.95" :shiftY="0.80" :adaptive="true" :height="'auto'">
      <div class="mx-3 md:mx-5 my-5">
        <div class="flex-1 flex pl-2 sm:items-stretch sm:justify-start mb-3">
          <div>
            <div class="flex space-x-4">
              <label @click="form.filial = 'curitiba'" class="text-gray-800 text-xs sm:text-base font-bold py-1 py-2 cursor-pointer" :class="form.filial === 'curitiba' ? 'text-green-700 px-4 rounded-md border border-green-700' : '' ">
                  Curitiba
              </label>
              <label @click="form.filial = 'pontagrossa'" class="text-gray-800 text-xs sm:text-base font-bold py-1 py-2 cursor-pointer" :class="form.filial === 'pontagrossa' ? 'text-green-700 px-4 rounded-md border border-green-700' : '' ">
                  Ponta Grossa
              </label>
              <label @click="form.filial = 'piraquara'" class="text-gray-800 text-xs sm:text-base font-bold py-1 py-2 cursor-pointer" :class="form.filial === 'piraquara' ? 'text-green-700 px-4 rounded-md border border-green-700' : '' ">
                  Piraquara
              </label>
              <label @click="form.filial = 'irati'" class="text-gray-800 text-xs sm:text-base font-bold py-1 py-2 cursor-pointer" :class="form.filial === 'irati' ? 'text-green-700 px-4 rounded-md border border-green-700' : '' ">
                  Irati
              </label>
            </div>
          </div>
        </div>
        <input
          v-model="form.nome"
          type="text"
          name="nome"
          class="h-14 border border-gray-200 text-gray-800 px-4 block w-full shadow-sm sm:text-sm font-Montserrat border border-white rounded-xl focus:outline-none"
          placeholder="Seu nome"
        />
        <textarea
          v-model="form.mensagem"
          class="border border-gray-200 mt-3 text-gray-800 px-4 py-4 block w-full shadow-sm sm:text-sm font-Montserrat border-b-2 border borde-white rounded-xl focus:outline-none"
          placeholder="Sua Mensagem">
        </textarea>
        <button
            type="button"
            @click="sendMensagem"
            class="border-2 border-green-600 bg-green-600 text-base text-white py-2 px-4 font-Montserrat rounded mt-3"
        >
            Iniciar conversa
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'app',
   data() {
    return {
      form: {
        filial: "curitiba",
        nome: "",
        mensagem: "",
      },
    };
  },
  components: {
    Header,
    Footer,
  },
  methods: {
    openModal () {
       this.$modal.show('modal_zap');
    },
    getFilial() {
      switch (this.form.filial) {
        case "curitiba": return '5541996869640';
        case "pontagrossa": return '5542991131716';
        case "piraquara": return '5541999437122';
        case "irati": return '554234232525';
        default: return '5541996869640';
      }
    },
    sendMensagem (){
      const link = `https://api.whatsapp.com/send?phone=${this.getFilial()}&text=Olá sou ${this.form.nome}. ${this.form.mensagem}`;
      window.open(link, '_blank');
    }
  }
}
</script>
