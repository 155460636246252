<template>
  <div class="home">
    <!-- Banner !-->
    <carousel :perPage="1" :autoplay="true" :loop="true" :autoplayTimeout="6000">
      <slide>
        <div class="flex items-center w-full h-38em md:h-55em bg-cover bg-no-repeat bg-center pt-4 md:pt-8 pb-4 md:pb-8 relative bg-bannerhome_mobile md:bg-bannerhome">
          <div class="mx-5 md:mx-12 2xl:mx-96">
            <div class="grid grid-cols-12 gap-2">
              <div class="col-span-12 lg:col-span-8 ml-0 text-center px-2 md:px-0 md:text-left md:ml-7 lg:ml-5">
                <h1
                  class="text-white font-Montserrat text-2xl md:text-5xl"
                >
                  Empresa conceituada no segmento de britas e agregados
                </h1>
                <button
                  @click="$modal.show('modal_zap')"
                  class="border-none bg-primary mt-8 md:absolute inset-y-2/3 w-60 md:w-60 hover:bg-white hover:text-primary text-base text-white h-14 font-Montserrat hover:bg-terciary"
                >
                  Entre em contato
                </button>
              </div>
            </div>
          </div>
        </div>
      </slide>
      <slide>
        <div class="flex items-center w-full h-38em md:h-55em bg-cover bg-no-repeat bg-center pt-4 md:pt-8 pb-4 md:pb-8 relative bg-boscardin_slider2_mobile md:bg-boscardin_slider2">
          <div class="mx-5 md:mx-12 2xl:mx-96">
            <div class="grid grid-cols-12 gap-2">
              <div class="col-span-12 lg:col-span-8 ml-0 text-center px-2 md:px-0 md:text-left md:ml-7 lg:ml-5">
                <h1
                  class="text-white font-Montserrat text-2xl md:text-5xl"
                >
                  Pedreiras Boscardin é tradição e qualidade
                </h1>
                <button
                  @click="$modal.show('modal_zap')"
                  class="border-none bg-primary mt-8 md:absolute inset-y-2/3 w-60 md:w-60 hover:bg-white hover:text-primary text-base text-white h-14 font-Montserrat hover:bg-terciary"
                >
                  Entre em contato
                </button>
              </div>
            </div>
          </div>
        </div>
      </slide>
      <slide>
        <div class="flex items-center w-full h-38em md:h-55em bg-cover bg-no-repeat bg-center pt-4 md:pt-8 pb-4 md:pb-8 relative bg-boscardin_slider3_mobile md:bg-boscardin_slider3">
          <div class="mx-5 md:mx-12 2xl:mx-96">
            <div class="grid grid-cols-12 gap-2">
              <div class="col-span-12 lg:col-span-8 ml-0 text-center px-2 md:px-0 md:text-left md:ml-7 lg:ml-5">
                <h1
                  class="text-white font-Montserrat text-2xl md:text-5xl"
                >
                  60 anos de experiência no setor
                </h1>
                <button
                  @click="$modal.show('modal_zap')"
                  class="border-none bg-primary mt-8 md:absolute inset-y-2/3 w-60 md:w-60 hover:bg-white hover:text-primary text-base text-white h-14 font-Montserrat hover:bg-terciary"
                >
                  Entre em contato
                </button>
              </div>
            </div>
          </div>
        </div>
      </slide>
      <slide>
        <div class="flex items-center w-full h-38em md:h-55em bg-cover bg-no-repeat bg-center pt-4 md:pt-8 pb-4 md:pb-8 relative bg-boscardin_slider4_mobile md:bg-boscardin_slider4">
          <div class="mx-5 md:mx-12 2xl:mx-96">
            <div class="grid grid-cols-12 gap-2">
              <div class="col-span-12 lg:col-span-8 ml-0 text-center px-2 md:px-0 md:text-left md:ml-7 lg:ml-5">
                <h1
                  class="text-white font-Montserrat text-2xl md:text-5xl"
                >
                  Atuamos na extração da rocha e beneficiamento de materiais
                </h1>
                <button
                  @click="$modal.show('modal_zap')"
                  class="border-none bg-primary mt-8 md:absolute inset-y-2/3 w-60 md:w-60 hover:bg-white hover:text-primary text-base text-white h-14 font-Montserrat hover:bg-terciary"
                >
                  Entre em contato
                </button>
              </div>
            </div>
          </div>
        </div>
      </slide>
      <slide>
        <div class="flex items-center w-full h-38em md:h-55em bg-cover bg-no-repeat bg-center pt-4 md:pt-8 pb-4 md:pb-8 relative bg-boscardin_slider5_mobile md:bg-boscardin_slider5">
          <div class="mx-5 md:mx-12 2xl:mx-96">
            <div class="grid grid-cols-12 gap-2">
              <div class="col-span-12 lg:col-span-8 ml-0 text-center px-2 md:px-0 md:text-left md:ml-7 lg:ml-5">
                <h1
                  class="text-white font-Montserrat text-2xl md:text-5xl"
                >
                  É hoje uma das empresas mais conceituadas do segmento
                </h1>
                <button
                  @click="$modal.show('modal_zap')"
                  class="border-none bg-primary mt-8 md:absolute inset-y-2/3 w-60 md:w-60 hover:bg-white hover:text-primary text-base text-white h-14 font-Montserrat hover:bg-terciary"
                >
                  Entre em contato
                </button>
              </div>
            </div>
          </div>
        </div>
      </slide>
    </carousel>
    <!-- Info !-->
    <div id="info" class="py-12 md:py-28 mx-5 md:mx-12 2xl:mx-96">
      <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12 md:col-span-6">
          <img src="@/assets/boscardin_experiencia.png" alt="boscardin 60 anos de experiência" />
        </div>
        <div class="col-span-12 md:col-span-6 md:px-12 md:pt-20">
            <h2
              class="text-gray-900 font-Montserrat font-semibold text-2xl md:text-4xl"
            >
              Pedreiras Boscardin é tradição e qualidade
            </h2>
            <p class="text-gray-600 font-Montserrat text-base mt-5">
              Pedreiras Boscardin, empresa familiar liderada por Agostinho Boscardin, iniciou suas atividades nos anos de 1950 em Riozinho – Irati – Paraná.
            </p>
            <p class="text-gray-600 font-Montserrat text-base mt-3">
              Atua na produção de britas/agregados. É hoje uma das empresas mais conceituadas do segmento. 
            </p>
            <button
             @click="$router.push('/empresa')"
              class="border-none bg-primary text-base text-white py-3 font-Montserrat mt-4 w-full md:w-60 hover:bg-secondary hover:text-white"
            >
              Conheça nossa história
            </button>
        </div>
      </div>
    </div>
    <div class="m-5 md:mx-12 2xl:mx-96">
      <hr class="border border-gray-200" />
    </div>
    <!-- Conheça !-->
    <div id="conheca" class="py-12 md:py-28 mx-5 md:mx-12 2xl:mx-96">
      <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12 md:col-span-6">
          <h2
              class="text-gray-900 font-Montserrat font-semibold text-2xl md:text-4xl"
            >
              Conheça os serviços que nós oferecemos
          </h2>
        </div>
      </div>
      <div class="grid grid-cols-12 gap-6 mt-12">
        <div class="col-span-12 md:col-span-6">
          <div class="w-full py-6 px-6 md:py-12 md:px-12 bg-terciary h-80">
            <h2
              class="text-gray-900 font-Montserrat text-xl md:text-4xl"
            >
              Linha de produtos
            </h2>
            <p class="text-gray-600 font-Montserrat text-base mt-2 mb-2 md:mt-4 md:mb-3">
              Fornecemos pedras diretas da mineração, garantindo assim, a melhor qualidade, além da confiança de quantitativos na hora da entrega. 
            </p>
            <router-link to="/servicos" class="text-secondary font-bold font-Montserrat text-sm hover:underline">Saber mais</router-link>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div class="w-full py-6 px-6 md:py-12 md:px-12 bg-terciary h-80">
            <h2
              class="text-gray-900 font-Montserrat text-xl md:text-4xl"
            >
              Locação de equipamentos
            </h2>
            <p class="text-gray-600 font-Montserrat text-base mt-4 mb-3">
              Nossos equipamentos de britagem proporcionam maior produção, possibilitando que o material permaneça dentro dos padrões de qualidade e dentro das normas técnicas oficiais de operação.
            </p>
            <router-link to="/servicos" class="text-secondary font-bold font-Montserrat text-sm hover:underline">Saber mais</router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- Interessado !-->
    <div id="interessado" class="py-12 md:py-28 bg-layout bg-cover bg-no-repeat bg-center">
      <div class="mx-5 md:mx-12 2xl:mx-96">
        <div class="grid grid-cols-12 gap-6">
          <div class="col-span-12 md:col-span-8">
            <h2
                class="text-white font-Montserrat text-2xl md:text-5xl"
              >
                Interessado nos nossos serviços? Entre em contato com a gente.
            </h2>
            <button
              @click="$modal.show('modal_zap')"
              class="border-none bg-white text-base font-semibold text-gray-900 py-6 font-Montserrat mt-4 w-full md:w-60 hover:bg-secondary hover:text-white mt-10"
            >
              Entrar em contato
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>
